.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.center-vert {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hidden {
    display:none !important;
}

.form-button-group {
    margin-top: 12px;
}

.width-100-p {
    width: 100%;
}

.form-header {
    margin-right: 12px;
    margin-left: 12px;
    margin-bottom: 8px;
    margin-top: 8px;
}

.form-content {

}

.form-footer {
    margin-right: 12px;
    margin-left: 12px;
    margin-bottom: 8px;
    margin-top: 8px;
}

.glow-on-hover {
    border: 3px solid transparent;
}

.glow-on-hover:hover {
    box-shadow: 5px 5px 15px 5px rgb(106, 106, 106);
}

.tpd-statement-address-hr-style {
    border: 0;
    height: 1px;
    background: lightgray;
    max-width: 30%;
    min-width: 300px;
    display: flex;
    align-self: center;
}

.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
}

.tpd-statement-detail-hide {
    visibility: collapse;
}

.oe-hide {
    visibility: hidden;
}

html, body {
    overflow-x: hidden;
}

body {
    position: relative
}

.animated {
    animation: bounce 1s infinite;
}

@keyframes bounce {
    0%, 50%, 100% {transform: translatex(0);}
    40% {transform: translatex(15px);}
    60% {transform: translatex(7.5px);}
}

.required {
    color: red;
}

.accordion {
    width: 85%;
}

.accordion::before {
    background-color: rgba(0, 0, 0, 0) !important;
}

.accordion-label {
    margin-left: 10px;
    margin-bottom: 10px;
    color: #337ab7;
    cursor: pointer;
}

.accordion-label:hover {
    text-decoration: underline;
    color: #23527c;
}

.accordion-label svg {
    padding-left: 5px;
    color: black;
}

.accordion-details {
    margin: 5px 10px;
    border-radius: 3px;
    background-color: #f5f5f5;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
    border: 1px solid #e3e3e3;
}

.flexible-container.practice-information {
    top: 16px;
}

.practice-blurb {
    padding-top: 5px !important;
    padding-bottom: 5px;
}

.small-text {
    font-size: 0.7em;
}

.terms-of-use-footer {
    font-size: .7em;
}

.terms-of-use-footer > div {
    justify-content: center;
    color: #337ab7;
}

.bump-right, #medicalQuestions {
    margin-left: 5px !important;
}

#medicalQuestions > div{
    margin-top: 5px;
    margin-bottom: 5px;
}

.medical-information-spacer {
    margin: 5px;
}

.practice-information-card {
    background-color: lightgrey;
}

.minisite-card-header > div > .MuiCardHeader-title {
    font-size: 14px;
}
.minisite-card-header {
    max-height: 16px;
}

.service-plan-field {
    margin-bottom: 10px !important;
}

.remove-provider-link {
    font-size: 14px;
    color: red;
}

.remove-provider-link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.code-verification-indicator {
    align-content: center;
    vertical-align: middle;
    justify-content: center;
    display: flex;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px 10px;
    border: 1px solid black;
}

.code-verification-indicator,.has-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #3c763d;
}

.code-verification-indicator,.has-error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #a94442;
}

.event-shape-option-label {
    word-wrap: break-word;
    padding-right: 16px;
}
