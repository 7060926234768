table tbody tr td.success {
    background: lightgray;
}

mark {
    background: #ff0;
    padding: 0;
}

.margin-bottom-5 {
    margin-bottom: 5px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-left-10 {
    margin-left: 10px !important;
}

.font-18 {
    font-size: 18px;
}

.provider-image-max-85 {
    max-width: 85px;
    max-height: 85px;
}

.provider-image-size-aspect {
    width: auto;
    height: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.panel-footer a {
    text-transform: uppercase;
}

.panel.panel-selected {
    border-color: #66afe9;
}

.flexible-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.form-horizontal .form-group-lg label.control-label {
    font-size: 14px;
}

.flexible-container iframe,
.flexible-container object,
.flexible-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.form-margin-top {
    margin-top: 15px;
}

label.btn > input[type='radio'] {
    display: none;
}

/*ddSlick override*/
.dd-options {
    position: relative;
    z-index: 0;
}

.dd-option-image {
    width: auto;
    height: auto;
}

.dd-selected-image {
    width: auto;
    height: auto;
}

.available-time {
    cursor: pointer;
    color: blue;
}

.available-time:hover {
    background-color: #f5f5f5;
}

.no-available-time::after {
    content: '-';
    color: lightgrey;
}

.no-available-times-margin {
    margin: 0px 10px;
}

.appointmentTime {
    margin: 0 10px;
}

.date .mon {
    display: block;
    text-align: center;
    /* padding:9px 0px 0px 0px; */
    font-size: 11px;
    color: #bf5549;
    font-weight: bold;
    line-height: 110%;
    text-transform: uppercase;
}

.date .day {
    display: block;
    text-align: center;
    /* padding:0px 0px 8px 0px; */
    font-size: 30px;
    font-weight: bold;
    color: #333;
    line-height: 100%;
}

.date .time {
    display: block;
    text-align: center;
    font-size: 16px;
    color: #333;
    line-height: 100%;
}

hr.hr-appointment-cards {
    margin-top: 0px;
}

hr.hr-appointment-card {
    margin: 6px 0 0px 0;
}

.appointment-summary-provider {
    margin-right: 5px;
    max-width: 64px;
    height: auto;
}

.appointment-summary-info {
    padding-right: 20px;
    word-break: break-word;
}

.control-label.required::after {
    color: red;
    content: '*';
    position: absolute;
    margin-left: 6px;
}

.form-control-static.required::before {
    color: red;
    content: '* ';
}

.checkbox.required span.required {
    color: red;
    /*content: '* ';*/
}

.checkbox.invalid {
    color: red;
}

.highlight {
    padding: 14px 14px;
    margin-bottom: 14px;
    background-color: #f7f7f9;
    border: 1px solid #e1e1e8;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    word-wrap: break-word;
}

.DTTT.btn-group {
    float: left;
    position: relative;
    top: 0px;
    right: 0px;
}

.tpd-practice-remove {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.tpd-white-space__pre-wrap {
    white-space: pre-wrap;
}

.sixteenFont {
    font-size: 16px;
}

.fa-pulse {
    display: inline-block;
    -moz-animation: pulse 1.5s infinite linear;
    -o-animation: pulse 1.5s infinite linear;
    -webkit-animation: pulse 1.5s infinite linear;
    animation: pulse 1.5s infinite linear;
}

@-webkit-keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.spinner {
    width: 40px;
    height: 40px;
    margin: 10px auto;
}

.medical-information-spacer {
    margin-top: 10px;
}

.error {
    padding: 15px;
}

.has-success .tpd-btn {
    border-color: #3c763d;
}

.has-error .tpd-btn {
    border-color: #a94442;
}

.has-error .tpd-btn:focus {
    border-color: #632525;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c35e5e;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c35e5e;
    outline: 0 none;
}

.has-success .tpd-btn:focus {
    border-color: #632525;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
    outline: 0 none;
}

.tpd-btn:focus {
    background-color: white;
}

.tpd-send:hover {
    text-decoration: underline;
    cursor: pointer;
}

.tpd-practice-info-terms-conditions {
    display: flex;
    justify-content: center;
}

input[type="date"] {
    display: block;
    -webkit-appearance: textfield;
}

.form-actions .btn[disabled] {
    opacity: .55;
}

/*Raised with Wiggle*/
.suggested-action-horizontal {
    -webkit-animation: bounce 2s infinite;
    -moz-animation: bounce 2s infinite;
    -o-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

.suggested-action {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2);
    -webkit-animation: btnWiggle 7s infinite;
    -moz-animation: btnWiggle 7s infinite;
    -o-animation: btnWiggle 7s infinite;
    animation: btnWiggle 7s infinite;
}

@-webkit-keyframes btnWiggle {
    0% {
        -webkit-transform: rotate(0deg);
    }
    2% {
        -webkit-transform: rotate(-5deg);
    }
    3.5% {
        -webkit-transform: rotate(5deg);
    }
    5% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
    }
}

@-o-keyframes btnWiggle {
    0% {
        -o-transform: rotate(0deg);
    }
    2% {
        -o-transform: rotate(-5deg);
    }
    3.5% {
        -o-transform: rotate(5deg);
    }
    5% {
        -o-transform: rotate(0deg);
    }
    100% {
        -o-transform: rotate(0deg);
    }
}

@keyframes btnWiggle {
    0% {
        transform: rotate(0deg);
    }
    2% {
        transform: rotate(-5deg);
    }
    3.5% {
        transform: rotate(5deg);
    }
    5% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateX(0); }
    40% {
        -webkit-transform: translateX(15px); }
    60% {
        -webkit-transform: translateX(10px); } }
@-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateX(0); }
    40% {
        -moz-transform: translateX(15px); }
    60% {
        -moz-transform: translateX(10px); } }
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0); }
    40% {
        -webkit-transform: translateX(15px);
        -moz-transform: translateX(15px);
        -ms-transform: translateX(15px);
        -o-transform: translateX(15px);
        transform: translateX(15px); }
    60% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px); }
}

/*----------------------------------------------------*/

/* AddEvent theme CSS
---------------------------------------------------------------------- */

/* The button */
.addeventatc {
    display: inline-block;
    position: relative;
    z-index: 999998
}

.addeventatc:hover {
    text-decoration: underline;
    cursor: pointer;
}

.addeventatc .start, .addeventatc .end, .addeventatc .timezone, .addeventatc .title, .addeventatc .description, .addeventatc .location, .addeventatc .organizer, .addeventatc .organizer_email, .addeventatc .facebook_event, .addeventatc .all_day_event, .addeventatc .date_format, .addeventatc .alarm_reminder, .addeventatc .recurring, .addeventatc .attendees, .addeventatc .calname {
    display: none !important;
}

/* The drop down */
.addeventatc_dropdown {
    width: 200px;
    position: absolute;
    z-index: 99999;
    padding: 6px 0px 0px 0px;
    background: #fff;
    text-align: left;
    display: none;
    margin-top: 4px;
    margin-left: -1px;
    border-top: 1px solid #c8c8c8;
    border-right: 1px solid #bebebe;
    border-bottom: 1px solid #a8a8a8;
    border-left: 1px solid #bebebe;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -webkit-box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
}

.addeventatc_dropdown span {
    display: block;
    line-height: 100%;
    background: #fff;
    text-decoration: none;
    font-size: 14px;
    color: #333;
    padding: 9px 10px 9px 40px;
}

.addeventatc_dropdown span:hover {
    background-color: #f4f4f4;
    color: #000;
    text-decoration: none;
    font-size: 14px;
}

.addeventatc_dropdown em {
    color: #999 !important;
    font-size: 12px !important;
}

.addeventatc_dropdown .frs {
    background: #fff;
    color: #cacaca !important;
    cursor: pointer;
    font-size: 9px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 110% !important;
    padding-left: 10px;
    position: absolute;
    right: 10px;
    text-align: right;
    text-decoration: none;
    top: 5px;
    z-index: 101;
}

.addeventatc_dropdown .frs:hover {
    color: #999 !important;
}

.addeventatc_dropdown .ateappleical {
    background-image: url(https://addevent.com/gfx/dropdown-apple-t1.png);
    background-position: 13px 50%;
    background-repeat: no-repeat;
}

.addeventatc_dropdown .ateoutlook {
    background-image: url(https://addevent.com/gfx/dropdown-outlook-t1.png);
    background-position: 13px 50%;
    background-repeat: no-repeat;
}

.addeventatc_dropdown .ateoutlookcom {
    background-image: url(https://addevent.com/gfx/dropdown-outlook-t1.png);
    background-position: 13px 50%;
    background-repeat: no-repeat;
}

.addeventatc_dropdown .ategoogle {
    background-image: url(https://addevent.com/gfx/dropdown-google-t1.png);
    background-position: 13px 50%;
    background-repeat: no-repeat;
}

.addeventatc_dropdown .ateyahoo {
    background-image: url(https://addevent.com/gfx/dropdown-yahoo-t1.png);
    background-position: 13px 50%;
    background-repeat: no-repeat;
}

.addeventatc_dropdown .atefacebook {
    background-image: url(https://addevent.com/gfx/dropdown-facebook-t1.png);
    background-position: 13px 50%;
    background-repeat: no-repeat;
}

.addeventatc_dropdown .copyx {
    height: 21px;
    display: block;
    position: relative;
    cursor: default;
}

.addeventatc_dropdown .brx {
    height: 1px;
    overflow: hidden;
    background: #e0e0e0;
    position: absolute;
    z-index: 100;
    left: 10px;
    right: 10px;
    top: 9px;
}





/* Ember modal dialog
----------------------------------------------------------------------- */
.ember-modal-dialog {
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 10px #222;
    padding: 10px;
}

.ember-modal-overlay.translucent {
    background-color: rgba(128, 128, 128, .77);
}

.ember-modal-overlay {
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 50;
}

.ember-modal-dialog {
    z-index: 50;
    position: fixed;
}

.ember-modal-dialog.ember-modal-dialog-in-place {
    position: relative;
}

.cc-invisible {
    display: none;
}
